// Others
import config from '@/config/env-constants';

function getDefaultIssuanceObj() {
	return {
        issuanceId: '',
        company: '',
        companyId: '',
        firstName: '',
        lastName: '',
        userId: '',
        userCompanyId: '',
        userCompany: '',
        assetType: '',
        assetTypeId: '',
        assetCode: '',
        assetName: '',
        description: '',
        notes: '',
        status: config.assetIssuanceStatus.ISSUED,
        dateIssued: null,
        issuedBy: '',
        dateCancelled: null,
        cancelledBy: '',
        dateReturned: null,
        receivedBy: '',
    };
}

export const AssetIssuanceUtil = {
    getDefaultIssuanceObj
}