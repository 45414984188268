<template>
	<div>
		<b-tabs>
			<b-tab title="Details" active>
				<b-card-text>
					<b-form-group label="Issuance ID" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.issuanceId }}
						</span>
					</b-form-group>

					<b-form-group label="Status" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<AssetIssuanceRowStatus :item="row.item" />
					</b-form-group>

					<b-form-group label="Issued To" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.firstName + ' ' + row.item.lastName }}
					</b-form-group>

					<b-form-group label="Asset" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="numFont">
							{{ row.item.assetName + ' (' + row.item.assetCode + ')' }}
						</span>
					</b-form-group>

					<b-form-group label="Date Issued" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{
							row.item.dateIssued
							? getFormattedDateWithTime(row.item.dateIssued)
							: '-'
						}}
					</b-form-group>

					<b-form-group label="Issued By" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getUserDisplay(row.item.issuedBy) }}
					</b-form-group>

					<b-form-group label="Issuance Reason" label-cols-sm="2" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.description" />
						</span>
					</b-form-group>

					<span v-show="row.item.status === assetIssuanceStatus.RETURNED">
						<b-form-group label="Date Returned" label-cols-sm="2" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{
								row.item.dateReturned
								? getFormattedDateWithTime(row.item.dateReturned)
								: '-'
							}}
						</b-form-group>
						<b-form-group label="Received By" label-cols-sm="2" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.receivedBy }}
						</b-form-group>
					</span>

					<span v-show="row.item.status === assetIssuanceStatus.CANCELLED">
						<b-form-group label="Date Cancelled" label-cols-sm="2" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{
								row.item.dateCancelled
								? getFormattedDateWithTime(row.item.dateCancelled)
								: '-'
							}}
						</b-form-group>
						<b-form-group label="Cancelled By" label-cols-sm="2" label-class="font-weight-bold pt-0"
							label-align-sm="right">
							{{ row.item.cancelledBy }}
						</b-form-group>
					</span>
				</b-card-text>
			</b-tab>

			<b-tab title="Change Log">
				<b-card>
					<b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
						:per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>
						<template v-slot:cell(dateCreated)="row">{{
							getFormattedDateWithTime(row.item.dateCreated)
						}}</template>

						<template v-slot:cell(old)="row">
							<AssetIssuanceRowStatus :item="row.item.old" />
							<br />
							<i>{{ row.item.old && row.item.old.notes ? row.item.old.notes : '-' }}</i>
						</template>

						<template v-slot:cell(new)="row">
							<AssetIssuanceRowStatus :item="row.item.new" />
							<br />
							<i>{{ row.item.new && row.item.new.notes ? row.item.new.notes : '-' }}</i>
						</template>
					</b-table>

					<b-row>
						<b-col md="8" sm="12" class="my-1">
							<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
								class="my-0" />
						</b-col>
					</b-row>
				</b-card>
			</b-tab>
		</b-tabs>

		<b-row class="mt-4 mb-2">
			<b-col sm="12" class="text-sm-right">
				<b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Component
import AssetIssuanceRowStatus from '@/views/asset/assetIssuance/AssetIssuanceRowStatus';

// API & Database
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Util
import { DateUtil } from '@/utils/dateutil';
import { UserUtil } from '@/utils/userutil';

// Others
import config from '@/config/env-constants';
import truncate from 'vue-truncate-collapsed';

export default {
	name: 'asset-issuance-details-view',
	components: {
		AssetIssuanceRowStatus,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			items: [],
			fields: [
				'dateCreated',
				{ key: 'old', label: 'Previous Value' },
				{ key: 'new', label: 'New Value' },
			],
			currentPage: 1,
			perPage: 5,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			selAssetIssuance: {},
			assetIssuanceStatus: config.assetIssuanceStatus,
		};
	},
	mounted() {
		let assetIssuanceId = this.row.item.id;
		this.retrieveChangeLog(assetIssuanceId);
	},
	methods: {
		getUserDisplay(userId) {
			return UserUtil.getUserDisplay(this.allUsersObj, userId);
		},
		async retrieveChangeLog(assetIssuanceId) {
			if (assetIssuanceId) {
				let param = {
					collection: 'assetIssuances',
					id: assetIssuanceId,
				};

				let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
				this.items = Object.values(results);
				this.totalRows = this.items.length;
			}
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
};
</script>