<template>
	<div>
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		<b-row class="my-2">
			<b-col lg="12" sm="12" class="my-2">
				<i class="fa fa-filter"></i>&nbsp;
				<b>FILTER OPTIONS</b>
				&nbsp;&nbsp;

				<b-button v-show="!showFilterOptions" size="sm" @click="toggleFilterOptions" v-b-tooltip.hover
					variant="outline-secondary" title="Show/Hide Filter Options">
					<i class="icon-arrow-down"></i>
				</b-button>

				<b-button v-show="showFilterOptions" size="sm" @click="toggleFilterOptions" v-b-tooltip.hover
					variant="outline-secondary" title="Show/Hide Filter Options">
					<i class="icon-arrow-up"></i>
				</b-button>
			</b-col>
		</b-row>
		<b-row class="mt-2 mb-4" v-show="showFilterOptions">
			<b-col sm="12">
				<b-form-group label="Asset Type">
					<b-form-select name="Asset Type" v-model="filterBy.assetType" :options="assetTypeOptions" />
				</b-form-group>
			</b-col>
			<b-col sm="12">
				<b-form-group label="Asset Code">
					<b-form-input name="Asset Code" type="text" class="numFont" v-model="filterBy.assetCode"
						maxlength="25" />
				</b-form-group>
			</b-col>
			<b-col sm="12">
				<b-button variant="success" @click="onFilterRequest" class="mb-1">
					Search
				</b-button>&nbsp;
				<b-button variant="primary" @click="resetFilters" class="mb-1">
					Reset
				</b-button>
			</b-col>
		</b-row>

		<b-row class="my-2">
			<b-col sm="10" class="my-1 text-sm-left">
				<b-button size="sm" @click="selectAllRows" class="mb-1">
					Select all
				</b-button>
				&nbsp;
				<b-button size="sm" @click="clearSelectedRows" class="mb-1">
					Clear
				</b-button>
				&nbsp;
				<span class="total-display">
					Selected: {{ Object.keys(selected).length }}
				</span>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
			</b-col>
			<b-col sm="2" class="my-1 text-sm-right">
				<b-button variant="success" @click="addSelectedAssets" v-b-tooltip.hover.top="'Add Selected Assets'">
					<i class="fa fa-plus"></i>
				</b-button>
			</b-col>
		</b-row>

		<b-row class="mx-1">
			<b-table ref="assetsTable" show-empty striped hover :items="items" :fields="fields" responsive selectable
				select-mode="multi" @row-clicked="rowClicked" selected-variant="primary">
				<template v-slot:cell(selected)="{ rowSelected }">
					<template v-if="rowSelected">
						<span aria-hidden="true">&check;</span>
						<span class="sr-only">Selected</span>
					</template>
					<template v-else>
						<span aria-hidden="true">&nbsp;</span>
						<span class="sr-only">Not selected</span>
					</template>
				</template>

				<template v-slot:cell(assetCode)="row">
					<span class="numFont">
						{{ row.item.assetCode }}
					</span>
				</template>

			</b-table>
		</b-row>

		<b-row>
			<b-col sm="6" class="text-sm-right">
				<b-input-group prepend="Show" append="/ Page">
					<b-form-select :options="pageOptions" v-model="pagination.perPage" />
				</b-input-group>
			</b-col>
			<b-col sm="6">
				<b-pagination align="right" @change="onPageChanged" :total-rows="pagination.total"
					:per-page="pagination.perPage" v-model="pagination.currentPage" class="my-0" limit="1" prev-text="Prev"
					next-text="Next" hide-goto-end-buttons />
			</b-col>
		</b-row>
	</div>
</template>

<script>
// Utils
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { SortUtil } from '@/utils/sortUtil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';

// Others
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'asset-inventory-table',
	components: {
		Loading,
	},
	props: {
		selCompany: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'selected',
					label: '#',
					sortable: false,
				},
				{
					key: 'assetCode',
					label: 'Code',
					sortable: true,
				},
				{
					key: 'assetType',
					label: 'Type',
					sortable: true,
				},
			],
			pageOptions: [5, 10, 15, 25, 50, 100],
			pagination: {
				perPage: 5,
				currentPage: 1,
				total: 0,
				startAt: '',
				endAt: '',
			},
			totalRows: 0,

			defaultFilterBy: {
				company: config.companyDefaultValue,
				status: 'Stored',
				assetType: config.assetTypeDefaultValue,
				assetCode: '',
				direction: 'asc',
			},
			filterBy: {
				company: config.companyDefaultValue,
				status: 'Stored',
				assetType: config.assetTypeDefaultValue,
				assetCode: '',
				direction: 'asc',
			},
			prevFilterBy: {},

			allAssetsObj: {},
			allAssetTypesObj: {},
			assetTypeOptions: [],

			// array of selecteds from table
			selected: [],

			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
			showFilterOptions: false,
		};
	},
	watch: {
		selCompany: async function (newVal) {
			this.filterBy.company = newVal;
			this.updateAssetTypeOptions(newVal.id);
			await this.onFilterRequest();
		},
		'pagination.perPage'(value) {
			this.resetPagination();
			this.retrieveData(1, value, this.pagination.startAt, {
				...this.filterBy,
			});
		},
		'pagination.currentPage'(newVal, oldVal) {
			if (newVal === 1) {
				this.resetPagination();
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
			} else {
				if (newVal > oldVal) {
					this.filterBy.direction = 'asc';
					this.retrieveData(
						newVal,
						this.pagination.perPage,
						this.pagination.endAt,
						{
							...this.filterBy,
						}
					);
				} else {
					this.filterBy.direction = 'desc';
					this.retrieveData(
						newVal,
						this.pagination.perPage,
						this.pagination.startAt,
						{
							...this.filterBy,
						}
					);
				}
			}
		},
		items(value) {
			this.$nextTick(() => {
				let vm = this;
				// Apply existing highlighting for selected rows
				let selectedItems = _.intersectionBy(value, this.selected, 'id');

				selectedItems.forEach((item) => {
					// retrieve index with highlights
					let index = value.indexOf(item);
					// apply hightlights programmatically
					vm.$refs.assetsTable.selectRow(index);
				});
			});
		},
	},
	async mounted() {
		this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
		this.filterBy.company = this.selCompany;

		this.updateAssetTypeOptions(this.selCompany.id);
		await this.onFilterRequest();

		EventBus.$on('onDeleteAsset', (deletedAsset) => {
			let assetArr = _.map(this.items, 'assetCode');
			if (!_.includes(assetArr, deletedAsset.assetCode)) {
				this.items.push(deletedAsset);
				this.items = SortUtil.sortByAttr('assetCode', this.items);
				this.totalRows = this.items.length;
			}
		});
	},
	methods: {
		updateAssetTypeOptions(companyId) {
			let assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);
			this.assetTypeOptions = _.filter(assetTypeItems, (o) => {
				return (
					o.text === ' - Please select - ' || o.value.originId === companyId
				);
			});
		},
		updateTable(assetsObj) {
			let vm = this;
			_.forEach(assetsObj, (value, key) => {
				this.allAssetsObj[key] = value;

				// update items from asset table
				let index = _.findIndex(vm.items, (o) => {
					return o.assetCode === key;
				});
				if (index >= 0) {
					vm.items[index] = value;
				}
			});
			this.$store.dispatch('updateAllAssets', this.allAssetsObj);

			let filteredObj = _.groupBy(this.items, 'id');
			_.forEach(filteredObj, (value, key) => {
				// filter by assetType
				if (
					!_.isEmpty(this.filterBy.assetType.id) &&
					value.assetTypeId !== this.filterBy.assetType.id
				) {
					delete filteredObj[key];
				}
				// filter by assetCode
				if (
					!_.isEmpty(this.filterBy.assetCode) &&
					value.assetCode !== this.filterBy.assetCode
				) {
					delete filteredObj[key];
				}
			});

			if (_.size(filteredObj) <= this.items.length) {
				this.pagination.startAt = '';
				if (_.size(filteredObj) > 0) {
					this.pagination.startAt = Object.values(filteredObj)[0];
				}
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
			}
		},

		resetPagination() {
			this.filterBy.direction = 'asc';
			this.pagination.startAt = '';
			this.pagination.endAt = '';
		},
		onPageChanged(currentPage) {
			this.pagination.currentPage = currentPage;
		},

		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		validateFilter() {
			let isValid = true;

			if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(
					`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`
				);
				isValid = false;
			}

			return isValid;
		},
		async onFilterRequest() {
			if (!this.validateFilter()) {
				return;
			}

			// reset startAt during fresh query
			this.pagination.startAt = '';

			await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
				...this.filterBy,
			});
			this.prevFilter = { ...this.filterBy };

			// clear selected rows
			this.clearSelectedRows();
		},
		resetFilters() {
			// reset to default
			this.filterBy = { ...this.defaultFilterBy };
			this.prevFilter = { ...this.filterBy };

			// clear selected rows
			this.clearSelectedRows();
		},

		async retrieveData(page, perPage, startAt, filterBy) {
			// Show Loader
			this.isLoading = true;

			try {
				let filters = {
					companyId: !filterBy.company.id ? '' : filterBy.company.id,
					status: !filterBy.status ? '' : filterBy.status,
					assetTypeId: !filterBy.assetType.id ? '' : filterBy.assetType.id,
					assetCode: !filterBy.assetCode ? '' : filterBy.assetCode,
				};

				const { data } = await assetIssuanceApi.paginateAssetsForIssuance(
					page,
					perPage,
					startAt,
					filterBy.direction,
					filters,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.items = data.items;

					let newStartAt = '';
					let newEndAt = '';
					if (!_.isEmpty(this.items)) {
						newStartAt = this.items[0].assetCode;
						newEndAt = this.items[this.items.length - 1].assetCode;
					}

					let total = 0;
					if (this.items.length < data.perPage) {
						total = data.perPage * (data.currentPage - 1) + this.items.length;
					} else {
						total = data.perPage * data.currentPage + 1;
					}

					this.pagination = {
						perPage: data.perPage,
						currentPage: data.currentPage,
						total: total,
						startAt: newStartAt,
						endAt: newEndAt,
					};
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			}

			// Hide Loader
			this.isLoading = false;
		},

		rowClicked(item) {
			if (_.findIndex(this.selected, { id: item.id }) < 0) {
				this.selected.push(item);
			} else {
				for (let i = 0; i < this.selected.length; i++) {
					if (this.selected[i].id === item.id) {
						this.selected.splice(i, 1);
						break;
					}
				}
			}
		},
		selectAllRows() {
			this.$refs.assetsTable.selectAllRows();
			this.items.forEach((item) => {
				if (_.findIndex(this.selected, { id: item.id }) < 0) {
					this.selected.push(item);
				}
			});
		},
		clearSelectedRows() {
			this.$refs.assetsTable.clearSelected();

			// clear selected rows
			this.selected = [];
		},
		addSelectedAssets() {
			let selAssetsArr = Object.values(this.selected);

			if (selAssetsArr.length === 0) {
				this.$toaster.warning('Please select the assets you want to include.');
			} else {
				EventBus.$emit('addSelectedAssets', selAssetsArr);
				// Remove the transported assets from the list of available assets
				this.removeSelectedAssets(selAssetsArr);
			}
		},
		removeSelectedAssets(selAssetsArr) {
			let transportedAssetsOptions = [];
			selAssetsArr.forEach((asset) => {
				transportedAssetsOptions.push(asset.id);
			});

			this.items = this.items.filter(function (value) {
				return !transportedAssetsOptions.includes(value.id);
			});
			this.totalRows = this.items.length;
		},

		toggleFilterOptions() {
			this.showFilterOptions = !this.showFilterOptions;
		},
	},
	beforeDestroy() {
		EventBus.$off('onDeleteAsset');
	},
};
</script>