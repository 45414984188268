import { db } from '@/config/firebase';

async function getAssetIssuancesByAssetCodes(assetCodes) {
    let assetIssuancesObj = {};

    const dbRef = db.collection('assetIssuances')
        .where("assetCode", "in", assetCodes);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let assetIssuanceObj = doc.data();
        assetIssuancesObj[id] = {
            id: id,
            ...assetIssuanceObj
        }
    });

    return assetIssuancesObj;
}


export default {
    getAssetIssuancesByAssetCodes,
}